@import 'Layout';

.Nav {
	position: fixed;
	width: 100%;
	height: $navbar-padding;
	background-color: #080451;
	color: white;
	display: flex;
	justify-content: center;

	@media screen and (min-width: $desktop-max-width) {
		height: $large-navbar-padding;
	}
}

.NavBar {
	height: $navbar-padding !important;

	@media screen and (min-width: $desktop-max-width) {
		height: $large-navbar-padding !important;
		display: flex;
		align-items: center;
	}
}

.NavBarToolBar {
	height: $navbar-padding !important;

	@media screen and (min-width: $desktop-max-width) {
		height: $large-navbar-padding !important;
		max-width: $desktop-max-width;
		min-width: $desktop-max-width;
	}
}

.NavToolbar {
	background-color: #080451;
	margin: auto;
	max-width: 800px;
	width: 100%;
}

.NavFlexContainer {
	width: 100%;
	height: $navbar-padding;
	display: flex;
	justify-content: center;
	align-items: center;

	@media (min-width: 400px) {
		max-width: 70%;
	}
}

.CartIconContainer {
	position: relative;
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-right: 10px;
}

.HomeIconContainer {
	position: relative;
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-left: 10px;
	text-decoration: none;
	color: white;
}

.LinkIcon {
	height: 100%;
	max-height: 40px;
}

.VaughtsLogo {
	height: 45px;
	max-height: 45px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.NavIcon {
	height: 30px;
	max-height: 30px;
	width: 30px;
	max-width: 30px;
}

.AppDrawer {
	background-color: #080451;
	color: white;
	height: 100%;
	width: 250px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	@media screen and (min-width: $desktop-max-width) {
		width: 500px;
	}
}

.LeftGroup {
	display: flex;
	justify-content: space-around;

	@media screen and (max-width: $desktop-max-width) {
		display: none;
	}
}

.TextLink {
	color: white;
	text-decoration: none;
	font-family: 'ChaparralProItalic';
}

.LeftButton {
	// For now, lets keep the hamburger menu
	// @media screen and (min-width: $desktop-max-width) {
	// 	display: none !important;
	// }
}

.ShopButton {
	padding-right: 16px;
}
