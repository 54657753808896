@import '../ui/Layout';

.CartRoute {
	width: 100%;
	height: 100%;
	background-color: #eee;
	color: #080451;
	overflow: auto;

	@media (min-width: $desktop-max-width) {
		display: flex;
		justify-content: center;
		min-width: $desktop-max-width;
	}
}

.CartRouteContainer {
	width: 100%;
	height: 100%;

	@media (min-width: $desktop-max-width) {
		max-width: $desktop-max-width;
	}
}

.CloseIcon {
	cursor: pointer;
}

.CheckoutContainer {
	margin-top: 34px;

	@media (min-width: $desktop-max-width) {
		display: flex;
		justify-content: space-around;
	}
}

.CartContainer {
	display: grid;
	gap: 16px;
	height: 100%;
	grid-auto-rows: 1fr;
}

.CartStack {
	margin-top: 16px;
	/* gap: 16px; */
	padding-bottom: 100px;

	@media (min-width: $desktop-max-width) {
		padding-bottom: 16px;
		min-width: 60%;
	}
}

.CartRow {
	display: flex;
	flex-direction: row;
	gap: 16px;
	/* align-items: center; */
	padding-right: 16px;
	padding-bottom: 15px;
}

.DividerRow {
	padding: 0 16px;
}

.CartRowDetails {
	flex-grow: 1;
	font-size: 1.0625rem;
	font-style: normal;
	line-height: 1.125rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.CartRowText {
}

.CartTitleAndClose {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 20px;
}

.SpacerRow {
	height: 100px;

	@media (min-width: $desktop-max-width) {
		display: none;
	}
}

.CheckoutRow {
	display: flex;
	align-items: flex-end;
	flex-direction: column;
	width: 100%;
	position: fixed;
	bottom: 0;
	background-color: white;
	color: #080451;

	@media (min-width: $desktop-max-width) {
		max-width: 300px;
		height: 100%;
		position: relative;
	}
}

.CartRowImage {
	margin-left: 16px;
	height: 105px;
	box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
}

.CartRowControls {
	display: flex;
	flex-direction: row;
	gap: 1rem;
	align-items: center;
}

.CartRowQuantityButton {
	min-width: auto !important;
	padding: 0 !important;

	&:disabled {
		opacity: 0.4;
	}
}

.CartRowPrice {
	font-weight: 700;
	padding-top: 4px;
}

.CartRowQuantity {
	width: 1.25rem;
	text-align: center;
}

.CheckoutButton {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #1b2797;
}

.EmptyCart {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.ShopButton {
	margin-top: 1.5rem;
}

.PricingTitleSection {
	@media (max-width: $desktop-max-width) {
		display: none;
	}
}
.PricingTitle {
	@media (min-width: $desktop-max-width) {
		display: block;
		font-size: 1.5rem;
		padding-bottom: 10px;
	}
}

.Pricing {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.PricingSubtotals {
	gap: 8px;
	display: flex;
	flex-direction: column;
	padding-top: 30px;
	padding-left: 30px;
	padding-right: 30px;

	@media (min-width: $desktop-max-width) {
		padding-top: 50px;
		gap: 12px;
	}
}
.PricingTotal {
	font-size: 20px;
	padding-left: 8px;
	display: flex;
	justify-content: space-between;
	padding-top: 10px;
	padding-bottom: 30px;
	padding-left: 30px;
	padding-right: 30px;

	@media (min-width: $desktop-max-width) {
		padding-bottom: 50px;
	}
}

.PricingSubtotal {
	display: flex;
	justify-content: space-between;
}

.PricingSubtotalLast {
	padding-bottom: 2px;
}

.Divider {
	border-bottom: solid 1px #d8d8d8;
}

.ErrorModal {
	text-align: center;
}

.ErrorTitle {
	font-family: 'Gotham';
}

.ErrorDescription {
	line-height: 1.5rem;
	font-weight: 100;
}
