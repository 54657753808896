@import '../ui/Layout';

.About {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	background: #ffdec9;
}

.WhiteContainer {
	background: #ffffff;
	color: #080451;
	display: flex;
	flex-direction: column;
	height: 100%;

	@media (min-width: $desktop-max-width) {
		flex-direction: row-reverse;
	}
}

.AboutTitle {
	color: #080451;
	text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
	padding: 20px;
	font-family: Gotham-Bold;
	font-size: 2rem;
	font-style: normal;
	line-height: 2.5rem; /* 114.286% */
	letter-spacing: -0.7px;

	@media (min-width: $desktop-max-width) {
		font-size: 3.5rem;
		line-height: 4rem;
	}
}

.AboutDescription {
	color: #080451;
	font-size: 1rem;
	font-family: Gotham-Light;
	font-style: normal;
	font-weight: 400;
	line-height: 1.5625rem; /* 156.25% */
	padding: 0 20px;
}

.SignatureContainer {
	display: flex;
	width: 100%;
	padding-top: 1.88rem;
}

.AboutPinkTitle {
	color: #080451;
	font-family: Gotham-Bold;
	font-size: 1.5rem;
	font-style: normal;
	line-height: 1.875rem; /* 125% */
	letter-spacing: 0.05rem;
	padding: 0 24px;
	padding-top: 1.5rem;
	padding-bottom: 1rem;

	@media (min-width: $desktop-max-width) {
		font-size: 2.5rem;
		line-height: 3rem;
		padding-top: 64px;
		padding-right: 64px;
		padding-left: 64px;
	}
}

.AboutPinkTitleTwo {
	@media (min-width: $desktop-max-width) {
		padding-top: 0;
	}
}

.AboutPinkDescription {
	color: #080451;
	font-family: Gotham-Light;
	font-size: 1rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.375rem; /* 137.5% */
	padding: 24px;
	padding-top: 0;
	padding-bottom: 40px;

	@media (min-width: $desktop-max-width) {
		padding-right: 64px;
		padding-left: 64px;
		line-height: 1.8rem; /* 137.5% */
		font-weight: 700;
	}
}

.PinkContainer {
	background: #ffdec9;

	@media (min-width: $desktop-max-width) {
		max-width: calc($desktop-max-width + 200px);
		display: flex;
		flex-direction: row;
		padding: 64px 0;
	}
}

.PinkContainerTwo {
	background: #ffdec9;

	@media (min-width: $desktop-max-width) {
		max-width: calc($desktop-max-width + 200px);
		display: flex;
		flex-direction: row-reverse;
		padding: 32px 0;
		padding-left: 32px;
	}
}

.PenImage {
	width: 100%;

	@media (min-width: $desktop-max-width) {
		height: 100%;
		flex: 1 1 0;
		width: 50%;
	}
}

.PenTextSection {
	@media (min-width: $desktop-max-width) {
		padding: 16px 64px;
		flex: 1 1 0;
	}
}

.SignatureImage {
	width: 200px;
	padding-left: 20px;
}

.SignatureTitle {
	color: #000;
	font-family: Gotham;
	font-size: 1.25rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.875rem; /* 150% */
	letter-spacing: 0.15625rem;
	padding-left: 20px;
}

.SignatureSubtitle {
	color: #000;
	font-family: Chaparral Pro;
	font-size: 1.25rem;
	font-style: italic;
	font-weight: 400;
	line-height: 1.875rem;
	letter-spacing: 0.15625rem;
	padding-left: 20px;
	padding-bottom: 46px;
}

.FruitsImage {
	width: 100%;

	@media (min-width: $desktop-max-width) {
		height: 100%;
		max-height: 500px;
	}
}

.BeansImage {
	width: 100%;

	@media (min-width: $desktop-max-width) {
		height: 100%;
		max-height: 500px;
	}
}

.AboutPinkFinalTitle {
	padding-top: 32px;
	padding-left: 24px;
	padding-right: 24px;
	color: #080451;
	font-family: Gotham;
	font-size: 1.5rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.875rem; /* 125% */
	letter-spacing: 0.05rem;
}

.AboutFinalSpacer {
	padding-top: 64px;
}
