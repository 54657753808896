@import '../ui/Layout';

.Carousel {
	height: 60px;
}

.ChocolateImageContainer {
	position: relative;

	@media screen and (min-width: 450px) {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.ChocolateImage {
	max-width: 100%;
	width: 100%;
	animation: fadeIn 1.5s;

	@keyframes fadeIn {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}

	@media screen and (min-width: $desktop-max-width) and (width > 930px) {
		max-width: 700px;
		min-width: 325px;
	}

	@media screen and (min-width: $desktop-max-width) and (width < 930px) {
		max-width: 400px;
		min-width: 325px;
	}
}

.DesktopCarouselInterior {
	@media screen and (min-width: $desktop-max-width) {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		flex: 1;
	}
}

.CarouselButton {
	min-width: 45px;
	width: 45px;
	height: 46px;
	cursor: pointer;
}

.CarouselButtonLeft {
	position: absolute;
	left: 4px;
	height: 100%;

	@media screen and (min-width: $desktop-max-width) {
		position: relative;
		left: auto;
	}
}

.CarouselButtonDesktop {
	@media screen and (max-width: $desktop-max-width) {
		display: none;
	}
}

.CarouselButtonMobile {
	@media screen and (min-width: $desktop-max-width) {
		display: none;
	}
}

.CarouselButtonRight {
	position: absolute;
	right: 4px;
	height: 100%;

	@media screen and (min-width: $desktop-max-width) {
		position: relative;
		left: auto;
	}
}

.ChocolateRoute {
	width: 100%;
	height: 100%;
	min-height: 100%;
	transition: background-color 0.5s ease;
	font-size: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow: auto;

	@media (max-height: 570px) {
		padding-bottom: 80px;
	}
}

.ChocolatePage {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;

	animation: fadeIn 1.5s;

	@keyframes fadeIn {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}

	@media (min-width: $desktop-max-width) {
		max-width: min(1200px, 90%);
		flex-direction: row;
		justify-content: space-between;
	}
}

.ChocolateFooter {
	display: flex;
	flex-direction: column;
	height: calc(40% + 77px);
	justify-content: space-between;

	@media (min-width: $desktop-max-width) {
		height: auto;
		flex-direction: unset;
		flex: 1;
		margin-left: -250px;
		z-index: 1;
	}

	@media screen and (min-width: $desktop-max-width) and (width > 930px) {
		width: 700px;
	}

	@media screen and (min-width: $desktop-max-width) and (width < 930px) {
		width: 400px;
		flex: auto;
		margin-left: -100px;
	}
}

.ChocolateTextSection {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 0 32px;

	@media (min-width: $desktop-max-width) {
		max-width: $desktop-max-width;
		width: 100%;
		flex: 1;
	}
}

.ChocolatePercentage {
	font-size: 2.25rem;
	font-family: 'Gotham-Black';
	font-style: normal;
	line-height: 2.1875rem; /* 97.222% */
	letter-spacing: 0.125rem;

	@media (min-width: $desktop-max-width) {
		font-size: 3rem;
		line-height: 3rem;
	}
}

.ChocolatePercentageHome {
	animation: slide-left-anim 1s ease-out forwards;
}

.ChocolateTitle {
	font-family: 'Gotham-Bold';
	font-size: 1.625rem;
	font-style: normal;
	line-height: 2.1875rem;
	letter-spacing: 0.125rem;

	@media (min-width: $desktop-max-width) {
		font-size: 2.5rem;
		line-height: 3rem;
	}
}

.ChocolateTitleHome {
	animation: slide-left-anim 1s ease-out forwards;
}

@keyframes slide-left-anim {
	0% {
		opacity: 0;
		transform: translateX(20%);
	}
	100% {
		opacity: 1;
		transform: translateX(0%);
	}
}

@keyframes slide-right-anim {
	0% {
		opacity: 0;
		transform: translateX(-20%);
	}
	100% {
		opacity: 1;
		transform: translateX(0%);
	}
}

.ChocolateSubtitle {
	margin-top: 1rem;
	font-size: 1.25rem;
	letter-spacing: 0.08931rem;
}

.ChocolateSubtitleText {
	margin-top: 1rem;
	font-size: 1rem;
	letter-spacing: 0.15625rem;
	animation: slide-left-anim 1s ease-out forwards;
}

.HomeButton {
	animation: slide-right-anim 1s ease-out forwards;
}

.Button {
	width: 95%;
	max-width: 375px;
	height: 66px;
	border-radius: 16px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.NoUnderline {
	text-decoration: none;
}

.ButtonContainer {
	width: 100%;
	position: fixed;
	bottom: 0;
}

.ChocolateButton {
	width: 100%;
	height: 45px;
	bottom: 0;
	border: 0;
	border-radius: 0 !important;
}
