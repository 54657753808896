$navbar-padding: 67px;
$large-navbar-padding: 75px;

$desktop-max-width: 800px;

.Layout {
	padding-top: $navbar-padding;
	width: 100%;
	height: calc(100% - #{$navbar-padding});
	height: -o-calc(100% - #{$navbar-padding}); /* opera */
	height: -webkit-calc(100% - #{$navbar-padding}); /* google, safari */
	height: -moz-calc(100% - #{$navbar-padding}); /* firefox */
	overflow: auto;

	@media screen and (min-width: $desktop-max-width) {
		padding-top: $large-navbar-padding;
		height: calc(100% - #{$large-navbar-padding});
		height: -o-calc(100% - #{$large-navbar-padding}); /* opera */
		height: -webkit-calc(100% - #{$large-navbar-padding}); /* google, safari */
		height: -moz-calc(100% - #{$large-navbar-padding}); /* firefox */
	}
}

.FooterButton {
	height: $navbar-padding;
	min-height: $navbar-padding;
	max-height: $navbar-padding;
	font-family: 'ChaparralProItalic';
	text-transform: 'none';
	font-size: '22px';

	@media (min-width: $desktop-max-width) {
		max-width: $desktop-max-width;
		min-height: 48px;
		max-height: 48px;
		height: 48px;
		display: flex;
		left: 50%;
		transform: translate(-50%, 0);
	}
}

.MobileButton {
	@media (min-width: $desktop-max-width) {
		display: none !important;
	}
}

// DESKTOP ONLY
.InlineButton {
	margin-top: 32px !important;

	@media (max-width: $desktop-max-width) {
		display: none !important;
	}
}
