@import '../ui/Layout';

.SuccessRoute {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #1b2797;
}

.SuccessCenteredColumn {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	height: 100%;
	padding: 0 32px;
}

.SuccessTitle {
	color: #ffd6ca;
	text-align: center;
	font-family: Gotham;
	font-size: 2rem;
	font-style: normal;
	font-weight: 700;
	line-height: 2.5rem;
	letter-spacing: 0.125rem;
	padding-bottom: 1rem;

	@media (min-width: $desktop-max-width) {
		font-size: 3.75rem;
		line-height: 4.6875rem; /* 125% */
		padding-bottom: 0;
	}
}

.UnwrappedImage {
	max-width: 225px;
	width: 100%;

	@media (min-width: $desktop-max-width) {
		max-width: 400px;
	}
}

.SuccessSubtitle {
	color: #fff;
	text-align: center;
	font-family: Gotham;
	font-size: 1rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.5rem;

	@media (min-width: $desktop-max-width) {
		font-size: 1.5625rem;
		line-height: 2.8125rem; /* 180% */
	}
}

.SuccessButton {
	display: flex;
	justify-content: center;
}

.ExtraDiv {
	height: 100px;
	width: 1px;
}
