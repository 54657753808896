body {
	margin: 0;
	/* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif; */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: 'Gotham';
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

body,
html {
	width: 100%;
	height: 100%;
	margin: 0px;
	padding: 0px;
	overflow-x: hidden;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
	only screen and (-o-min-device-pixel-ratio: 3/2),
	only screen and (min--moz-device-pixel-ratio: 1.5),
	only screen and (min-device-pixel-ratio: 1.5) {
	html,
	body {
		width: 100%;
		overflow-x: hidden;
	}
}

#root,
#root > div {
	height: 100%;
}

@font-face {
	font-family: 'Gotham'; /*Can be any text*/
	src: local('Gotham'), url('./fonts/Gotham-Medium.otf') format('opentype');
}

@font-face {
	font-family: 'Gotham-Black'; /*Can be any text*/
	src: local('Gotham-Black'), url('./fonts/Gotham-Black.otf') format('opentype');
}

@font-face {
	font-family: 'Gotham-Bold'; /*Can be any text*/
	src: local('Gotham-Bold'), url('./fonts/Gotham-Bold.otf') format('opentype');
}

@font-face {
	font-family: 'Gotham-Light'; /*Can be any text*/
	src: local('Gotham-Light'), url('./fonts/Gotham-Light.otf') format('opentype');
}

@font-face {
	font-family: 'ChaparralPro'; /*Can be any text*/
	src: local('ChaparralPro'),
		url('./fonts/ChaparralPro-Regular.otf') format('opentype');
}

@font-face {
	font-family: 'ChaparralProItalic'; /*Can be any text*/
	src: local('ChaparralProItalic'),
		url('./fonts/ChaparralPro-Italic.otf') format('opentype');
}
