@import '../ui/Layout';

.Details {
	width: 100%;
	height: 100%;
	font-size: 20px;
	overflow: auto;
}

.DetailsHeroContainer {
	@media (min-width: $desktop-max-width) {
		width: 100%;

		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.DetailsHero {
	@media (min-width: $desktop-max-width) {
		display: flex;
		flex-direction: row;
		max-width: calc($desktop-max-width + 200px);
		width: 100%;
		justify-content: center;
		align-items: center;
	}
}

.SnackBar {
	top: $navbar-padding !important;

	@media (min-width: $desktop-max-width) {
		top: calc($large-navbar-padding + 8px) !important;
	}
}

.Snack {
	@media (min-width: $desktop-max-width) {
		max-width: $desktop-max-width;
	}
}

.DetailsImage {
	width: 100%;
	margin-top: 35px;

	@media (min-width: $desktop-max-width) {
		margin-top: 0;
		width: auto;
		max-width: $desktop-max-width;
		flex: 1 1 0;
		width: 100%;
		max-width: 400px;
		height: 100%;
	}
}

.DetailsTitle {
	@media (min-width: $desktop-max-width) {
		font-size: 1.5rem;
	}
}

.DetailsTextSection {
	display: flex;
	justify-content: space-between;
	padding-top: 20px;
}

.DetailsChocolateTextSection {
	padding-top: 35px;

	@media (min-width: $desktop-max-width) {
		flex: 1 1 0;
		padding-top: 120px;
	}
}

.ChocolatePrice {
	margin-top: 2rem;
	font-size: 1.25rem;
	border-top: solid white 1px;
	border-bottom: solid white 1px;
	padding: 14px 0;

	@media (min-width: $desktop-max-width) {
		max-width: 160px;
	}
}

.ChocolateWeightAndPrice {
	letter-spacing: 0.08931rem;
}

.ChocolateWeight {
	font-family: 'Gotham-Light';
}

.ChocolateNotes {
	font-family: 'ChaparralProItalic';
	font-size: 1.375rem;
	font-weight: 400;
	line-height: 2rem; /* 145.455% */
	padding-top: 0.5rem;
}

.DetailsExplained {
	font-family: 'ChaparralProItalic';
	font-size: 1.375rem;
	font-weight: 400;
	line-height: 2.25rem; /* 145.455% */
	padding: 3rem 32px;

	@media (min-width: $desktop-max-width) {
		max-width: calc($desktop-max-width + 200px);
		margin: 0 auto;
	}
}

.IngredientsSection {
	background-color: #eeeeee;
	color: #1b2797;
	padding: 16px 32px;
	padding-bottom: 100px;
	display: flex;
	flex-direction: column;

	@media (min-width: $desktop-max-width) {
		// Subtract 64 to match 32 padding everywhere else
		max-width: calc($desktop-max-width + 200px - 150px);
		margin: 0 auto;
		margin-bottom: 0;
		flex-direction: row;
		gap: 80px;
		padding: 75px;
	}
}

.IngredientsTextSection {
	@media (min-width: $desktop-max-width) {
		max-width: 300px;
	}
}

.NutritionFactsSection {
	width: 100%;
}

.IngredientsLabel {
	font-size: 10px;
}

.IngredientsTitle {
	padding: 16px 0;
	font-size: 1.25rem;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	letter-spacing: 0.15625rem;
}

.IngredientsTitleLittle {
	font-family: 'Gotham-Light';
	font-size: 0.75rem;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	letter-spacing: 0.09375rem;
}

.IngredientsHeader {
	font-size: 16px;
	font-family: 'Gotham-Light';
	padding: 8px 0;
	font-weight: bold;

	@media (min-width: $desktop-max-width) {
		line-height: 1.4rem;
	}
}

.IngredientsSubtitle {
	font-size: 16px;
}

.IngredientsNutritionLabel {
	font-size: 12px;
}

.IngredientsNutritionTable {
	width: 100%;
	border-collapse: collapse;

	@media (min-width: $desktop-max-width) {
		line-height: 1.3rem;
	}
}

.IngredientsNutritionTable td {
	max-width: 200px;
	overflow: hidden;
	white-space: nowrap;
	font-size: 0.9rem;
}

.IngredientsNutritionFact {
	padding-left: 8px;
}

.IngredientsNutritionTable td:first-child:after {
	opacity: 0.2;
	content: ' . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .';
}
